<template>
  <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <BackgroundImage />
        </b-col>
        <b-col>
          <PersonalInformationGrid />
        </b-col>
      </b-row>
  </b-container>
</template>

<script lang="ts">
import BackgroundImage from "./components/BackgroundImage.vue";
import PersonalInformationGrid from "./components/PersonalInformationGrid/PersonalInformationGrid.vue";

export default {
  name: "About",
  components: {PersonalInformationGrid, BackgroundImage},
}

</script>


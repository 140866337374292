<template>
  <b-navbar class="sticky-top">
    <b-navbar-brand>
      <img alt="logo" class="logo-nav" src="../../assets/logo.png">
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>

.sticky-top {
  height: 50px;
  opacity: 0.8;
  border-bottom: 1px solid #F1F1F1;
  background: #FFFFFF;
}

.logo-nav {
  width: 45px;
}
</style>

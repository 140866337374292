<template>
  <footer>
    <div class="footer-top">
      <ul class="socials">
        <li class="github"><a href="https://github.com/martin-hirsch" target="_blank" data-hover="GitHub">GitHub</a></li>
        <li class="stackoverflow"><a href="https://stackoverflow.com/users/7417099/m-hirsch" target="_blank" data-hover="stackoverflow">stackoverflow</a></li>
        <li class="reddit"><a href="https://www.reddit.com/user/martinhirsch" target="_blank" data-hover="reddit">reddit</a></li>
      </ul>
    </div>
    <div class="footer-bottom">
      <div style="text-align: center;">
        <img src="../../assets/logo-bottom.png" alt="logo bottom" />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  width: 100%;
}

.footer-top{
  width: 100%;
  text-align: center;
  color: #cccccc;
  display: table;
}

.socials{
  width: 100%;
  margin: 0;
  padding: 0;
}

.socials * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.socials li {
  list-style: outside none none;
  float: left;
  overflow: hidden;
  width: 33.33333%;
  font-size: 13px;
}

.socials a {
  padding: 0.3em 0.8em;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
  padding: 25px 0;
  width: 100%;
}

.socials a:before,
.socials a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.socials a:before {
  bottom: 100%;
  display: block;
  height: 3px;
  width: 100%;
  content: "";
}

.socials a:after {
  padding: 0.3em 0.8em;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: white;
  white-space: nowrap;
  width: 100%;
  padding: 25px 0;
}

.socials li:hover a,
.socials .current a {
  transform: translateY(100%);
}

.github{
  background: #969696;
}
.github a:after{
  background-color: #3E2C00;
}
.reddit{
  background: #d8d8d8;
}
.reddit a:after{
  background-color: #FF4301;
}
.stackoverflow{
  background: #ABABAB;
}
.stackoverflow a:after{
  background-color: #F47F24;
}

.footer-bottom{
  width: 100%;
  background: #2f2f2f;
  padding: 80px 0;
  color: #636363;
}
</style>

<template>
  <b-container class="d-flex align-items-center justify-content-center" style="min-height: 650px">
  <b-container>
    <PersonalInformationGridRow keyColumn="Name" valueColumn="Martin Hirsch" />
    <PersonalInformationGridRow keyColumn="E-Mail" valueColumn="post@martinhirsch.de" />
    <b-row>
      <b-col>
        <p style="font-weight: 500">
          <font-awesome-icon :icon="['fas', 'biking']"/>
          seit 2019
        </p>
      </b-col>
      <b-col>
        <p>:</p>
      </b-col>
      <b-col>
        <p>{{distance}} km</p>
      </b-col>
    </b-row>
  </b-container>
  </b-container>
</template>

<script lang="ts">
import PersonalInformationGridRow from "./components/PersonalInformationGridRow.vue";
export default {
  name: 'PersonalInformationGrid',
  components: {PersonalInformationGridRow},
  data() {
    return {
      distance: '',
      items: []
    }
  },
  created() {
    fetch("../persistence/totalbikedistance.txt")
        .then(response => response.text())
        .then(data => {
          this.distance = data
        })
  },
  }
</script>

<style scoped>
p {
  font-size: 13px;
}
</style>
<template>
  <b-container fluid class="p-0">
    <Header />
    <About />
    <Footer />
  </b-container>
</template>

<script>
import Footer from './components/footer/Footer.vue'
import Header from './components/header/Header.vue'
import About from './components/about/About.vue'

export default {
  name: 'App',
  components: {
    Header,
    About,
    Footer
  }
}
</script>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');*/
/*body {*/
/*  font-family: 'Roboto';*/
/*}*/
/*----------------------------------------- 5) Typography --------------------------------------------------*/
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 0.8px;
  /*font-family: 'Roboto Slab', serif;*/
}
p {
  letter-spacing: 0.5px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 300;
}
#home h1 {
  line-height: 40px;
  letter-spacing: 0.6px;
}
#home .typed {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.5px;
  margin: 0 auto;
  opacity: 0.6;
}
.year {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
  /*font-family: 'Roboto', sans-serif;*/
}
.job {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #cccccc;
}
.company {
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #ececec;
  color: #cccccc;
  padding:8px 10px;
  border-radius: 6px;
  display: inline-block;
  margin-top: 5px;
  font-weight: 400;

}
/*----------------------------------------- 3) Helper --------------------------------------------------*/
.no-pad {padding: 0 !important; margin: 0 !important;}
.pad-80 {padding-top: 80px;}
.mar-t-50{margin-top: 50px !important;}
.mar-tm-10{ margin-top: -10px;}


/*---- span -----*/
.title-small span { position: relative;}
.title-small span:after {
  content: "";
  position: absolute;
  bottom: -20px;
  height: 3px;
  background: #DEDEDE;
  width: 28px;
  left: 0;
}
.title-small-center span {position: relative;}
.title-small-center span:after {
  content: "";
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  height: 3px;
  background: #DEDEDE;
  width: 28px;
  left: 0;
}

</style>

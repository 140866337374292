<template>
  <b-row>
    <b-col>
      <p class="keyColumn">{{ keyColumn }}</p>
    </b-col>
    <b-col>
      <p>:</p>
    </b-col>
    <b-col>
      <p>{{ valueColumn }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "PersonalInformationGridRow",
  props: {
    keyColumn: String,
    valueColumn: String
  }
}
</script>

<style scoped>
p {
  font-size: 13px;
}
.keyColumn {
  font-weight: 500;
}

</style>
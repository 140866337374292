<template>
  <div class="bg-about"/>
</template>
<script lang="ts">
export default {
  name: 'BackgroundImage'
}
</script>
<style scoped>
.bg-about {
  background: url('../../../assets/rathaus.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 650px;
}
</style>